import Blonding from '../../assets/Blonding.png'
import Bridal from '../../assets/Bridal.png'
import Extensions from '../../assets/Extensions.png'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

export default function HomeImages() {
  const images = [
    {
      original: Bridal,
      thumbnail: Bridal,
      originalWidth: 830,
    },
    {
      original: Blonding,
      thumbnail: Blonding,
      originalWidth: 830,

    },
    {
      original: Extensions,
      thumbnail: Extensions,
      originalWidth: 830,

    },
  ];
  return (
    <>
    <ImageGallery items={images} showPlayButton={false} showBullets={true} showThumbnails={false}/>
    </>

  )
}