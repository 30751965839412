export default function HomeHours() {
  return (
    <div id="home-hours">
      <div className="home-hours-title">Hours of Operations</div>
      <div className="hours-container">
        <div className="day-hours-container">
          <div className="day">Sunday:</div>
          <div className="hours">Closed</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Monday:</div>
          <div className="hours">Closed</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Tuesday:</div>
          <div className="hours">12:00pm - 7:00pm</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Wednesday:</div>
          <div className="hours">9:00am - 5:00pm</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Thursday:</div>
          <div className="hours">9:00am - 6:00pm</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Friday:</div>
          <div className="hours">9:00am - 5:00pm</div>
        </div>
        <div className="day-hours-container">
          <div className="day">Saturday:</div>
          <div className="hours">9:00am - 5:00pm</div>
        </div>
      </div>
    </div>
  )
}