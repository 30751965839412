export default function Reviews() {
  return (
    <div id="reviews">
      <div className="reviews-title">Customer Reviews!</div>
      <div className="reviews-container">
        <div className="reviews-item-container">
          <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>
        <div className="review-body">Best hairstylist ever, always pleasing the client and having their best interest 🤍🤍</div>
        <div className="review-name">-Mya</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>
          <div className="review-body">Marina is always so sweet and fun! She's very professional and caring, I feel like I'm sitting down with my best friend everytime I come in. </div>
          <div className="review-name">-Kat</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>
          <div className="review-body">Marina the only person I trust with my hair!! It always looks so amazing and lasts for months. </div>
          <div className="review-name">-Mara</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>

          <div className="review-body">Super Friendly, amazing service. Would recommend her to everyone I know!</div>
          <div className="review-name">-Jamie</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>

          <div className="review-body">I have been seeing Marina for highlights/color for a couple years now and she never dissapoints! I always leave with my expectations exceeded! She is very knowledgeable and skilled!</div>
          <div className="review-name">-Kaylee</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>

          <div className="review-body">Had the best experience with Marina today! Was very encouraging of trying a new style and executed it perfectly!</div>
          <div className="review-name">-Kayla</div>
        </div>
        <div className="reviews-item-container">
        <div className="stars">	&#9733;	&#9733;	&#9733;	&#9733;	&#9733;	</div>

          <div className="review-body">Very clean and professional place, everyone there is super sweet. I love going to Marina, she makes me feel welcomed and does an amazing job on my hair. She always makes sure her guests are well taken care of!</div>
          <div className="review-name">-Jenna</div>
        </div>
      </div>
    </div>
  )
}