import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok, faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons"
export default function Contact() {
  return (
    <div id="#contact">
      <div className="contact-title">Get in touch with me</div>
      <div className="contact-container">
        <div className="instagram">
        <a href="https://www.instagram.com/beautybymarinam/" className="social"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>
        <div className="facebook">
        <a href="https://www.facebook.com/BeautybyMarinaM/" className="social"><FontAwesomeIcon icon={faFacebook} /></a>
        </div>
        <div className="tiktok">
        <a href="https://www.tiktok.com/@beautybymarina_" className="social"><FontAwesomeIcon icon={faTiktok} /></a>
        </div>
      </div>

      <div className="email">
          <a href="mailto:Marina.montemayor2233@gmail.com"><button>Email Me!</button></a>
        </div>
    </div>
  )
}