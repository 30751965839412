export default function Policy() {
  return (
    <div id="policy">
      <div className="policy-title">NO CALL NO SHOW POLICY</div>
      <div className="policy-description">If you wish to reschedule your appointment you must cancel 24-48 hours before your appointment time. Failure to comply will result in a no call no show fee. If the service is greater than $100 you are required to pay 50% of your scheduled service. You may rebook once this fee has been paid.</div>
      <div className="payment-container">
        <div className="policy-payment">Zelle: Marina Montemayor 815-519-9792</div>
        <div className="policy-payment">Venmo: BeautyByMarinaM</div>
      </div>
      <div className="policy-disclaimer">*Please contact me for bridal hair contract*</div>
    </div>
  )
}