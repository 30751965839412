export default function Services() {
  return (
    <div id="services">
      <div className="services-title">SERVICE MENU</div>
      <div className="services-subtitle">BeautyByMarinaM</div>
      <div className="services-disclaimer">*Please note prices are starting prices and can go up depending on how much hair you have/how much product is being used.</div>
      <div className="services-container-upper">
        <div className="services-item">
          <div className="services-item-name">HIGHLIGHTS</div>
          <div className="services-item-description">Includes: First bowl of lightener, toner, and root toner. Any additional product used is extra cost as listed in add ons.</div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Full Foil</div>
            <div className="services-item-prices-price">$160+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Partial Foil</div>
            <div className="services-item-prices-price">$135+</div>
          </div>
        </div>
        <div className="services-item">
          <div className="services-item-name">BALAYAGE</div>
          <div className="services-item-description">Includes: First bowl of lightener, toner, and root toner. Any additional product used is extra cost as listed in add ons.</div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Full Foil</div>
            <div className="services-item-prices-price">$205+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Partial Foil</div>
            <div className="services-item-prices-price">$160+</div>
          </div>
        </div>
      </div>
      <div className="services-container-under">
        <div className="services-item">
          <div className="services-item-name">ADD ONS</div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Women's cut & style</div>
            <div className="services-item-prices-price">$40+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name deep-center">Deep conditioning treatment</div>
            <div className="services-item-prices-price">$35+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Extra color bowl</div>
            <div className="services-item-prices-price">$10+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Olaplex</div>
            <div className="services-item-prices-price">$30+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Special occasion style</div>
            <div className="services-item-prices-price">$75+</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Toner</div>
            <div className="services-item-prices-price">$30+</div>
          </div>
        </div>
        <div className="services-item">
          <div className="services-item-name">SPECIALTY SERVICES</div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Hair extensions</div>
            <div className="services-item-prices-price">by consultation</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name deep-center">Color correction</div>
            <div className="services-item-prices-price">by consultation</div>
          </div>
          <div className="services-item-prices-container">
            <div className="services-item-prices-name">Bridal hair</div>
            <div className="services-item-prices-price">by consultation</div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 25, fontSize: 35 }}>Meraki Salon + Studio</div>
    </div>
  )
}