import logo from '../../assets/logo.png'
export default function HomeFooter() {
  return (
    <div id="home-footer">
      <div className="home-footer-container">
        <div className="home-footer-intro-container">
          <div className="footer-intro">
            <div className="footer-name">BeautyByMarinaM</div>
            <div className="footer-title">at Meraki Salon + Studio</div>
            <div className="footer-address">5324 Williams Dr</div>
            <div className="footer-address">Roscoe, IL 61073</div>
            <a className="footer-phone" href="tel:+18152700955">(815) 270-0955</a>
          </div>
            <img src={logo} className="meraki-logo" alt="meraki-logo"></img>
        </div>
        <div className="home-footer-payments">
          <div className="footer-accepted-payments">
            <div className="footer-accepted-payments-title">Accepted Payments</div>
          <div className="footer-accepted-payments-container">
            <div className="venmo">CASH</div>
            <div className="zelle">DEBIT/CREDIT</div>
            <div className="cash">ZELLE</div>
            <div className="paypal">VENMO</div>
          </div>

          </div>
        </div>
      </div>
    </div>
  )
}