import './App.css'
import Navbar from './components/Navbar'
import Home from './components/Homepage/Home'
import Services from './components/Services&Prices/Services'
import About from './components/About/About'
import Contact from './components/Contact/Contact'
import Reviews from './components/Reviews/Reviews'
import Gallery from './components/Gallery/Gallery'
import Policy from './components/Policy/Policy'
import { Routes, Route, Navigate } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={ <Navigate to="/404" replace />} />
      </Routes>
    </div>
  )
}

export default App
