export default function HomeServices() {
  return (
    <div id="home-services">
      <div className="home-services-container">
        <div className="home-service-item">Bridal Hair</div>
        <div className="home-service-item">Blonding</div>
        <div className="home-service-item">Extensions</div>
      </div>
    </div>
  )
}