import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars} from "@fortawesome/free-solid-svg-icons"

export default function Navbar() {
    const openMenu = () => {
    var y = document.getElementById("myLinks");
    if (y.className === "nav-links") {
      y.className += "-responsive";
    } else {
      y.className = "nav-links";
    }
  };

  return (
    <div id="navbar">
      <div className="nav-logo">
        <a href="./">
        <div className="home-intro-container">
          <div className="home-intro">
            <div className="home-name">
              <div className="title">BeautyByMarinaM</div>
              <div className="sub-title">at Meraki Salon + Studio</div>
            </div>
          </div>
        </div>
        </a>
        </div>
        <div className="menu" onClick={(e) => {
          e.preventDefault()
          openMenu();
        }}>
        <FontAwesomeIcon icon={faBars} />
        </div>
      <div className="nav-links" id="myLinks">
        <a href="./services">Services</a>
        <a href="./about">About Me</a>
        <a href="./contact">Contact Me</a>
        <a href="./reviews">Reviews</a>
        <a href="./gallery">Gallery</a>
        <a href="./policy">Policy</a>
        <a href="https://www.vagaro.com/us04/merakisalonstudio"><button className="nav-booking">Book Now!</button></a>

      </div>
    </div>
    )
}