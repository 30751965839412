import HomeImages from './HomeImages'
import HomeBook from './HomeBook'
import HomeReviews from './HomeReviews'
import HomeHours from './HomeHours'
import HomeFooter from './HomeFooter'
import HomeServices from './HomeServices'

export default function Home() {
  return (
    <div id="home">
      <HomeImages />
      <HomeBook />
      <HomeServices />
      <HomeReviews />
      <HomeHours />
      <HomeFooter />
    </div>
  )
}