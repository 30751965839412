export default function HomeReviews() {
  return (
    <div id="home-reviews">
      <div className="home-reviews-title">Customer Reviews!</div>
      <div className="home-reviews-container">
        <div className="home-reviews-item-container">
        <div className="review-body">Best hairstylist ever, always pleasing the client and having their best interest 🤍🤍</div>
        <div className="review-name">-Mya</div>
        </div>
        <div className="home-reviews-item-container">
          <div className="review-body">Marina is always so sweet and fun! She's very professional and caring, I feel like I'm sitting down with my best friend everytime I come in. </div>
          <div className="review-name">-Kat</div>
        </div>
        <div className="home-reviews-item-container">
          <div className="review-body">Super Friendly, amazing service. Would recommend her to everyone I know!</div>
          <div className="review-name">-Jamie</div>
        </div>
      </div>
    </div>
  )
}