import Marina from '../../assets/marina.png'

export default function About() {
  return (
    <div id="about">
      <div className="about-title">About Me</div>
      <div className="about-container">
        <div className="about-portrait">
          <img src={Marina} alt="self-portait"></img>
        </div>
        <div className="about-description">
          <div className="description-name">Marina Montemayor</div>
          <div className="description-body">I am a licensed cosmetologist. I graduated from Cosmetology and spa academy in 2019. I love all things beauty. I am very passionate about my work. I specialize in bridal hair, blonding, and Weft extensions. My favorite thing about being behind the chair is helping others feel beautiful through hair transformations. I have continued taking many educational classes after graduating to ensure my guests they are being taken care of with the best color, techniques, and products for their hair type.</div>
        </div>
      </div>
    </div>
  )
}